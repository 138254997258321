import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConnected, addEvent } from './redux/websocketSlice';
import API from "./API";
import {fetchProjectById} from "./redux/nodesSlice";

let socket;

const WebSocketComponent = ({projectId}) => {
  const dispatch = useDispatch();
  const { connected, events } = useSelector((state) => state.websocket);
  const {lastUpdatedByMe} = useSelector(state => state.nodes)

  useEffect(() => {
    // Initialize WebSocket connection
    socket = new WebSocket(API.WS);

    socket.onopen = () => {
      console.log('Connected to WebSocket server.');
      dispatch(setConnected(true));

      // Join a room after connecting
      socket.send(JSON.stringify({ event: 'join-room', data: { room: projectId } }));
    };

    socket.onmessage = (message) => {
      const { event, data } = JSON.parse(message.data);

      if (event === 'event-received') {
        // console.log('Event received:', data, lastUpdatedByMe);
        dispatch(addEvent(data.message)); // Store event in Redux
        const serverUpdated = new Date(data.date).getTime()
        console.log('Event received:', data, 'me', lastUpdatedByMe, 'server', serverUpdated);

        // TODO lastUpdatedByMe === or ~== data.message.date
        // TODO What if received time is less than my time?
        // TODO ATTENTION
        // const wasUpdatedByMe = serverUpdated === lastUpdatedByMe
        // const wasUpdatedByOtherTab = !wasUpdatedByMe

        const needsUpdate = serverUpdated > lastUpdatedByMe
        if (needsUpdate)
          dispatch(fetchProjectById({projectId}))
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected.');
      dispatch(setConnected(false));
    };

    return () => {
      if (socket) socket.close(); // Cleanup on unmount
    };
  }, [dispatch, lastUpdatedByMe]);

  // useEffect(() => {
  //   socket.onmessage = (message) => {
  //     const { event, data } = JSON.parse(message.data);
  //
  //     if (event === 'event-received') {
  //       // console.log('Event received:', data, lastUpdatedByMe);
  //       dispatch(addEvent(data.message)); // Store event in Redux
  //       const serverUpdated = new Date(data.date).getTime()
  //       console.log('Event received:', data, 'me', lastUpdatedByMe, 'server', serverUpdated);
  //
  //       // TODO lastUpdatedByMe === or ~== data.message.date
  //       // TODO What if received time is less than my time?
  //       // TODO ATTENTION
  //       const wasUpdatedByMe = serverUpdated === lastUpdatedByMe
  //       const wasUpdatedByOtherTab = !wasUpdatedByMe
  //
  //       // const needsUpdate = wasUpdatedByOtherTab;
  //       const needsUpdate = serverUpdated > lastUpdatedByMe
  //       if (needsUpdate)
  //         dispatch(fetchProjectById({projectId}))
  //     }
  //   };
  // }, [lastUpdatedByMe])

  const fireEvent = () => {
    if (connected) {
      const message = 'Hello from client!';
      // socket.send(JSON.stringify({ event: 'fire-event', data: { room: 'room1', message } }));
      socket.send(JSON.stringify({ event: 'fire-event', data: { room: projectId, message } }));
      console.log('Fired event:', message);
    }
  };

  return (
    <div>
      {/*<div style={{backgroundColor : connected ? 'green' : 'red'}}>{connected ? 'Connected' : 'Disconnected'}</div>*/}

      {/*<button onClick={fireEvent} disabled={!connected}>*/}
      {/*  Fire Event {events.length}*/}
      {/*</button>*/}
    </div>
  );
};

export default WebSocketComponent;
